export const SHI_CHEN = {
  '0': '早子',
  '1': '丑',
  '3': '寅',
  '5': '卯',
  '7': '辰',
  '9': '巳',
  '11': '午',
  '13': '未',
  '15': '申',
  '17': '酉',
  '19': '戌',
  '21': '亥',
  '23': '晚子'
}
